<template>
  <component is="b-card">
    <b-card no-body class="mb-0">
      <b-row>
        <b-col>
          <b-form-group
            :label="$t('Product combination')"
            label-for="product-combination"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productCombinations"
              v-model="selectedProductCombination"
              label="product_shortcut"
              input-id="product-combination"
              @search="fetchProductCombinationOptions"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('Sub profile')" label-for="sub-profile">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="subProfiles"
              v-model="selectedSubProfile"
              label="name"
              input-id="sub-profile"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('Date')" label-for="date">
            <flat-pickr
              input-id="date"
              v-model="selectedDate"
              class="form-control"
              :config="{
                dateFormat: 'Y-m-d',
              }"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="refDesignationPriceHistory"
        class="position-relative"
        :items="fetchDesignationPriceHistory"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item>
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("Edit") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDesignationPriceHistory"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </component>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import useDesignationPriceHistory from "./useDesignationPriceHistory";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,

    vSelect,
    flatPickr,
  },
  data() {
    return {
      productCombinations: [],
      subProfiles: [],
      timeoutId: null,
    };
  },
  async mounted() {
    try {
      const res = await Promise.all([
        instance.get("/parameters/product-combinations-search/"),
        instance.get("/parameters/subprofiles/"),
      ]);
      this.productCombinations = res[0].data.results;
      this.subProfiles = res[1].data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },

  methods: {
    fetchProductCombinationOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get(
            "/parameters/product-combinations-search/",
            {
              params: {
                search,
              },
            }
          );
          this.productCombinations = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup() {
    const {
      fetchDesignationPriceHistory,
      tableColumns,
      perPage,
      currentPage,
      totalDesignationPriceHistory,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDesignationPriceHistory,
      refetchData,
      selectedProductCombination,
      selectedSubProfile,
      selectedUser,
      selectedDate,
    } = useDesignationPriceHistory();

    return {
      fetchDesignationPriceHistory,
      tableColumns,
      perPage,
      currentPage,
      totalDesignationPriceHistory,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDesignationPriceHistory,
      refetchData,
      // Filter
      avatarText,
      selectedProductCombination,
      selectedSubProfile,
      selectedUser,
      selectedDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
